import axios from 'axios';

export default {
  // getSecondEquipmentPanelsWithPagination(pageNumber, pageSize, nameFilter, eoIdFilter, productAssetModelIdFilter, skipEoObject, isLocalNsiFilter, duOrganizationName, dvOrganizationName, baseVoltageFilter, sortField, sortDesc) {
  getSecondEquipmentPanelsWithPagination(filterParams) {
    // const params = {
    //   filterParams.pageNumber,
    //   pageSize,
    //   nameFilter,
    //   eoIdFilter: eoIdFilter.length > 0 ? eoIdFilter.join('!;!') : '',
    //   productAssetModelIdFilter: productAssetModelIdFilter.length > 0 ? productAssetModelIdFilter.join('!;!') : '',
    //   skipEoObject,
    //   isLocalNsiFilter,
    //   duOrganizationName: duOrganizationName.length > 0 ? duOrganizationName.join('!;!') : '',
    //   dvOrganizationName: dvOrganizationName.length > 0 ? dvOrganizationName.join('!;!') : '',
    //   baseVoltageFilter: baseVoltageFilter.length > 0 ? baseVoltageFilter.join('!;!') : '',
    //   sortField,
    //   sortDesc
    // }
    filterParams.eoIdFilter = filterParams.eoIdFilter.length > 0 ? filterParams.eoIdFilter.join('!;!') : '';
    filterParams.productAssetModelIdFilter = filterParams.productAssetModelIdFilter.length > 0 ? filterParams.productAssetModelIdFilter.join('!;!') : '';
    filterParams.duOrganizationName = filterParams.duOrganizationName.length > 0 ? filterParams.duOrganizationName.join('!;!') : '';
    filterParams.dvOrganizationName = filterParams.dvOrganizationName.length > 0 ? filterParams.dvOrganizationName.join('!;!') : '';
    // filterParams.baseVoltageFilter = filterParams.baseVoltageFilter.length > 0 ? filterParams.baseVoltageFilter.join('!;!') : '';
    return axios.post('/api/SecondEquipmentPanels/GetSecondEquipmentPanelsWithPagination', filterParams);
  },
  getSecondEquipmentPanelItem(id) {
    return axios.get('/api/SecondEquipmentPanels/GetSecondEquipmentPanelItem', {
      params: {
        id
      }
    });
  },
  addSecondEquipmentPanel: (secondEquipmentPanel) => axios.post('/api/SecondEquipmentPanels', secondEquipmentPanel),
  updateSecondEquipmentPanel: (secondEquipmentPanel) => axios.put(`/api/SecondEquipmentPanels/${secondEquipmentPanel.id}`, secondEquipmentPanel),
  deleteSecondEquipmentPanel: (id) => axios.delete(`/api/SecondEquipmentPanels/${id}`),
  mergeSecondEquipmentPanels(mainId, secondaryIds) {
    return axios.post('/api/SecondEquipmentPanels/Merge', { mainId, secondaryIds });
  },
};
